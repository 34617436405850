<template>
  <div id="st-container" class="st-container">
    <SearchMultiSelect
      :selectedOptions="selected"
      :sourceOptions="destinationOptions"
      :hotDestinations="hotDestinations"
      :isCheckbox="3"
      :placeholder="$t('search-tab.choose-destination')"
      optionIcon="fa-map-marker-alt"
      tagIcon="fa-hotel"
      :theme="theme"
      @onChangeSelect="changedMultiSelection"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SelectDestinations',
  components: {
    SearchMultiSelect: () => import('@/components/atoms/SearchMultiSelect'),
  },
  props: {
    type: {
      type: String,
      default: 'tabs',
    },
    category: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      // category: 'GET_CURRENT_CATEGORY',
      lang: 'GET_LANGUAGE',
      searchContent: 'GET_SEARCH_CONTENT',
      isLanding: 'GET_IS_LANDING_PAGE',
    }),
    selected() {
      return this.searchContent.dest;
    },
    hotDestinations() {
      return this.category?.hotDestinations ?? [];
    },
  },
  watch: {
    lang() {
      this.makeSelectOption();
    },
  },
  data() {
    return {
      countSelect: 0,
      destinationOptions: [],
    };
  },
  methods: {
    makeSelectOption() {
      this.destinationOptions = [];
      if (this.category) {
        this.category.destinations.forEach((item, inx) => {
          this.destinationOptions.push({
            id: inx,
            value: item.code,
            label: item.countryName[this.lang] ? `${item.name[this.lang]}, ${item.countryName[this.lang]}` : item.name[this.lang],
          });
        });
      }
      this.getDestList();
    },
    getDestList() {
      const { query } = this.$route;
      if (query.categoryId === 'Organize_tour_packages' || (this.isLanding && query.dealType === 'Organize_tour_packages')) {
        const strDestination = decodeURIComponent(query.dest) || decodeURIComponent(query.destination) || '',
          destIdList = strDestination !== '' ? strDestination.split(',') : [];
        const dests = destIdList.map((id) => this.destinationOptions.find((dest) => dest.value === id));
        this.changedMultiSelection(dests);
        this.$store.dispatch('SET_SEARCH_ITEM', ['dest', dests]);
      } else {
        this.$store.dispatch('SET_SEARCH_ITEM', ['dest', []]);
      }
    },
    changedMultiSelection(pData) {
      this.$emit('changeDestination', pData);
    },
  },
  created() {
    this.makeSelectOption();
  },
};
</script>
<style scoped>
.st-container {
  overflow: visible;
}
</style>
<style>
input[type='search'] {
  margin: 0;
  padding: 0;
  border: 0;
}
.filter-tab .st-container .vs__dropdown-toggle {
  border-radius: 50px;
  padding: 0.575rem 1.35rem;
  height: 3rem;
}
.vs__selected {
  width: max-content;
}

.style-chooser .vs__dropdown-menu li {
  height: 45px;
  line-height: 35px;
  transition: 0.2s;
}

.style-chooser .vs__selected-options {
  display: contents;
}

.filter-tab form button.btn {
  height: 45px;
}
.checkbox-label {
  display: block;
}

.checkbox {
  position: absolute;
  left: 1vw;
}
.multiselect__tags {
  border-radius: 25px;
}
</style>
